import { useRouter } from 'next/router';
import { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring';

import { MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES } from 'features/filters/Filters.constants';

export type Query = string | ParsedUrlQueryInput | null | undefined;
export type Param = { [key: string]: string | string[] | undefined };

const useURLStateManagement = () => {
  const { query, push } = useRouter();
  const {
    section,
    sort,
    words,
    start,
    area,
    countyTown,
    radius,
    latitude,
    longitude,
    ...filtersQuery
  } = query;

  const [
    currentSectionQueryValue,
    makeQueryValue,
    modelQueryValue,
    carFilterQueryValue,
  ] = Array.isArray(section) ? section : [];

  const makePathParameter = makeQueryValue ? `/${makeQueryValue}` : undefined;
  const modelPathParameter =
    makeQueryValue && modelQueryValue ? `/${modelQueryValue}` : undefined;
  const carFilterPathParameter = carFilterQueryValue
    ? `/${carFilterQueryValue}`
    : undefined;

  const formatQueryValue = (queryValue?: string | string[]) => {
    return queryValue
      ? Array.isArray(queryValue)
        ? queryValue[0]
        : queryValue
      : '';
  };

  const sortQueryValue = formatQueryValue(sort);
  const keywordQueryValue = formatQueryValue(words);
  const paginationQueryValue = formatQueryValue(start);
  const radiusQueryValue = formatQueryValue(radius);
  const countyTownQueryValue = formatQueryValue(countyTown);
  const areaQueryValue = formatQueryValue(area);
  const latitudeQueryValue = formatQueryValue(latitude);
  const longitudeQueryValue = formatQueryValue(longitude);

  const filterQueryValues = Object.keys(filtersQuery).map((queryKey) => {
    return filtersQuery[queryKey];
  });

  /** Return an object that contains all the keys in
   * @param combinedFilterQueries with truthy values */
  const removeEmptyKeys = (combinedFilterQueries: Param) => {
    return Object.keys(combinedFilterQueries).reduce(
      (accumulatedQueries: Param, key) => {
        if (combinedFilterQueries[key]) {
          accumulatedQueries[key] = combinedFilterQueries[key];
        }
        return accumulatedQueries;
      },
      {},
    );
  };

  /** Return an object that contains all the key value pairs in
   * @param queryKeyValuePairs that do not appear in
   * @param hiddenQueryParams */
  const filterQueryKeyValuePairsForDisplay = (
    queryKeyValuePairs: Param,
    hiddenQueryParams?: Param,
  ) => {
    return Object.keys(queryKeyValuePairs).reduce(
      (accumulatedQueries: Param, key) => {
        if (
          hiddenQueryParams &&
          !Object.keys(hiddenQueryParams).includes(key)
        ) {
          accumulatedQueries[key] = queryKeyValuePairs[key];
        }
        return accumulatedQueries;
      },
      {},
    );
  };
  const updateURL = ({
    queryParams,
    pathParam,
    hiddenQueryParams,
  }: {
    queryParams?: Param;
    pathParam?: string;
    hiddenQueryParams?: Param;
  }) => {
    const { fallbackFilterName, fallbackFilterValue } =
      retrieveFallbackSEOFilterValue();

    const fallbackSEOFilterValue = fallbackFilterValue
      ? `/${fallbackFilterValue}`
      : '';

    const removePathParameter =
      typeof pathParam === 'string' && pathParam.length == 0;

    const updatedCarFilterPathParameter = removePathParameter
      ? fallbackSEOFilterValue
      : (pathParam && `/${pathParam}`) || carFilterPathParameter;

    const pathname = `/${currentSectionQueryValue}${makePathParameter ?? ''}${
      modelPathParameter ?? ''
    }${updatedCarFilterPathParameter ?? ''}`;

    const { section, latitude, longitude, makeModelEditable, ...rest } = query;

    /* If a make and model is applied, the path parameter is being removed
    and a query value that may be a SEO path parameter is available (fallbackFilterName),
    we remove that query since it has already been updated to be a path parameter */
    const formattedQueryValues =
      makePathParameter &&
      modelPathParameter &&
      removePathParameter &&
      fallbackFilterName &&
      fallbackFilterValue
        ? removeQuery(rest, fallbackFilterName)
        : rest;

    const combinedFilterQueries: Param = {
      latitude,
      longitude,
      makeModelEditable,
      ...formattedQueryValues,
      ...hiddenQueryParams,
      start: '',
      ...queryParams,
    };

    const filteredQueryKeyValuePairsForTruthyValues = removeEmptyKeys(
      combinedFilterQueries,
    );

    const displayQuery = filterQueryKeyValuePairsForDisplay(
      filteredQueryKeyValuePairsForTruthyValues,
      {
        latitude,
        longitude,
        makeModelEditable,
        ...hiddenQueryParams,
      },
    );

    const as = {
      pathname,
      query: Object.keys(displayQuery).length ? displayQuery : undefined,
    };

    push(
      {
        pathname,
        query: Object.keys(filteredQueryKeyValuePairsForTruthyValues).length
          ? filteredQueryKeyValuePairsForTruthyValues
          : undefined,
      },
      as,
      {
        shallow: true,
      },
    );
  };

  const resetURL = () => {
    const pathname = `/${currentSectionQueryValue}`;

    push(
      {
        pathname,
      },
      undefined,
      {
        shallow: true,
      },
    );
  };

  /** Update make and model values by (shallowly) pushing to the URL,
   * updating the path parameter if required. If no make or model is provided
   * then the path parameter must be converted to a query parameter, which requires the
   * name of that filter.
   * @param makeValue the new make value
   * @param modelValue the new model value
   * @param filterName the name of the filter whose value is the current SEO path paramter
   **/
  const updateMakeModel = (args: {
    makeValue: string;
    modelValue?: string;
    filterName?: string;
  }) => {
    const { makeValue, modelValue, filterName } = args;

    const make = makeValue ? `/${makeValue}` : '';
    const model = modelValue ? `/${modelValue}` : '';
    const { section, makeModelEditable, ...rest } = query;

    const { SEOQueryName, SEOQueryValue, SEOPathParameterAsQuery } =
      SEOFormatting({
        make,
        model,
        filterName,
      });

    const formattedQueryParameterAsSEOPathParameter = SEOQueryValue
      ? `/${SEOQueryValue}`
      : '';

    const pathname = `/${currentSectionQueryValue}${make}${model}${
      make && model
        ? carFilterPathParameter ?? formattedQueryParameterAsSEOPathParameter
        : ''
    }`;

    const updatedQuery = SEOPathParameterAsQuery
      ? { ...rest, ...SEOPathParameterAsQuery }
      : removeQuery(rest, SEOQueryName);

    const displayQuery = filterQueryKeyValuePairsForDisplay(updatedQuery, {
      latitude: latitudeQueryValue,
      longitude: longitudeQueryValue,
      makeModelEditable,
    });

    const as = {
      pathname,
      query: Object.keys(displayQuery).length ? displayQuery : undefined,
    };

    push(
      {
        pathname,
        query: updatedQuery,
      },
      as,
      {
        shallow: true,
      },
    );
  };

  const SEOFormatting = (args: {
    make: string;
    model?: string;
    filterName?: string;
  }) => {
    const { make, model, filterName } = args;

    const formatSEOPathParameterAsQuery = Boolean(
      makeQueryValue && modelQueryValue && (!make || !model),
    );
    const addSEOPathParameter = Boolean(
      (makeQueryValue ?? make) && (modelQueryValue ?? model),
    );

    const SEOPathParameterAsQuery =
      formatSEOPathParameterAsQuery && filterName
        ? { [filterName]: carFilterQueryValue }
        : undefined;

    const { queryName: SEOQueryName, queryValue: SEOQueryValue } =
      formatSEOPathParameterAsQuery || addSEOPathParameter
        ? filterForQueryParameter(MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES)
        : { queryName: '', queryValue: '' };

    return {
      SEOQueryName,
      SEOQueryValue,
      SEOPathParameterAsQuery,
    };
  };

  const filterForQueryParameter = (targets: Array<string>) => {
    const [queryName] = Object.keys(query).filter((key) => {
      return targets.find((target) => target === key);
    });

    return { queryName: queryName, queryValue: query[queryName] };
  };

  const removeQuery = (query: ParsedUrlQuery, target?: string) => {
    const updatedQuery = { ...query };
    if (target) {
      delete updatedQuery[target];
    }

    return updatedQuery;
  };

  const retrieveFallbackSEOFilterValue = () => {
    const fallbackKey = Object.keys(query).find((key) =>
      MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES.includes(key),
    );
    const fallbackFilterName = fallbackKey ?? '';
    const unformattedFallbackFilterValue = fallbackKey
      ? query[fallbackKey]
      : '';
    const fallbackFilterValue =
      typeof unformattedFallbackFilterValue === 'string'
        ? unformattedFallbackFilterValue
        : '';

    return {
      fallbackFilterName,
      fallbackFilterValue,
    };
  };

  return {
    currentSectionQueryValue,
    makeQueryValue,
    modelQueryValue,
    carFilterQueryValue,
    filterQueryValues,
    sortQueryValue,
    keywordQueryValue,
    paginationQueryValue,
    areaQueryValue,
    countyTownQueryValue,
    radiusQueryValue,
    latitudeQueryValue,
    longitudeQueryValue,
    updateURL,
    updateMakeModel,
    resetURL,
  };
};

export { useURLStateManagement };
