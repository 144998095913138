import { useState } from 'react';
import StatusCode from 'status-code-enum';

import { savedSearchApi } from 'api/savedSearches';

import { useSearchPageState } from 'components/SearchPage/context/SearchPageContext';
import { useUserContext } from 'contexts/UserContext';
import {
  useFiltersState,
  useFiltersDispatch,
} from 'features/filters/Filters.context';
import { useLocationContext } from 'features/location/Location.context';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import { useCreateSavedSearch } from 'components/SearchPage/hooks/useCreateSavedSearch';
import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import { mapMakeModelFilters } from 'components/SearchPage/hooks/useAds';

import {
  mapFiltersRequest,
  mapRangesRequest,
} from 'features/filters/Filters.mapper';

import { Link } from 'components/Toolkit/Button/Link';

import { formatPriceWithCurrency } from 'helpers/formatting';
import { fireToast } from 'helpers/Toasts';
import { truncateSearchTerms } from 'helpers/formatSearchTerms';
import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';
import { formatAuthorizationHeader } from 'helpers/auth';
import { findCountyByValue } from 'features/location/helpers';
import { API_CLIENT_TIMEOUT } from 'utils';
import { USER_MENU } from 'components/Layouts/components/UserMenu';

import type {
  IGetAdsRequestFilter,
  IGetAdsRequestRange,
  ISortField,
} from 'api/types/searchPageApiTypes';
import type { MakeModelItem } from 'features/filters/components/MakeModel/MakeModel.typed';

export enum NotificationFrequency {
  DAILY = 'DAILY',
  INSTANT = 'INSTANT',
  OFF = 'OFF',
}

function useSavedSearch(
  sectionName: string,
  accessToken: string | undefined,
  handleLogin: (url?: string) => void,
  sortFields: ISortField[],
) {
  const { user, setIsSavedSearchModalOpen, setIsInstantAlertAvailable } =
    useUserContext();
  const { filters, ranges, makeModel } = useFiltersState();

  const { baseUrl, saved: searchPageSaved } = useSearchPageState();
  const { getCountOfFiltersApplied, getFilterData } = useFiltersDispatch();
  const {
    currentSectionQueryValue: section,
    sortQueryValue: sort,
    keywordQueryValue: keywordSearchTerm,
    countyTownQueryValue: countyTown,
    areaQueryValue: area,
    radiusQueryValue: radius,
    carFilterQueryValue,
  } = useURLStateManagement();

  const { countyList } = useLocationContext();

  const { setSaved, createSavedSearch, saved, error, saving } =
    useCreateSavedSearch({ saved: searchPageSaved, accessToken });

  const initialName = generateSavedSearchName();

  const [name, setName] = useState(initialName);
  const [selected, setSelected] = useState<NotificationFrequency>(
    NotificationFrequency.INSTANT,
  );

  const countyPathParam = findCountyByValue(countyList, carFilterQueryValue);

  const county = area || countyPathParam?.value;

  function doorFormatter(filter: IGetAdsRequestFilter): IGetAdsRequestFilter {
    const values = filter.values.map((value) => `${value} doors`);
    return { ...filter, values };
  }

  function carFinanceFormatter(
    filter: IGetAdsRequestFilter,
  ): IGetAdsRequestFilter {
    if (filter.values.length && filter.values[0] === 'true') {
      return { ...filter, values: ['Price Per Month'] };
    }
    return filter;
  }

  function realisticPriceFormatter(
    filter: IGetAdsRequestFilter,
  ): IGetAdsRequestFilter {
    if (filter.values.length && filter.values[0] === 'true') {
      return { ...filter, values: ['realistic prices'] };
    }
    return filter;
  }

  function filterFormatter(filter: IGetAdsRequestFilter): IGetAdsRequestFilter {
    switch (filter.name) {
      case 'numDoors':
        return doorFormatter(filter);
      case 'carFinance':
        return carFinanceFormatter(filter);
      case 'containsValidPrice':
        return realisticPriceFormatter(filter);
      default:
        return filter;
    }
  }

  function addRangeDescriptor(range: IGetAdsRequestRange, descriptor: string) {
    return {
      ...range,
      from: range?.from
        ? range?.to && range.from !== range.to
          ? range.from
          : `${range.from}${descriptor}`
        : '',
      to: range?.to ? `${range.to}${descriptor}` : '',
    };
  }

  function priceFormatter(range: IGetAdsRequestRange) {
    const formatPriceValue = (value: string) => `
    ${formatPriceWithCurrency(parseInt(value), range.currency!)?.toString()!}
    `;
    return {
      ...range,
      to: range?.to ? formatPriceValue(range.to) : '',
      from: range?.from ? formatPriceValue(range.from) : '',
    };
  }

  function engineFormatter(range: IGetAdsRequestRange) {
    const formatEngine = (value?: string) =>
      value ? `${value.charAt(0)}.${value.charAt(1)}L` : '';
    return {
      ...range,
      to: range?.to ? formatEngine(range.to) : '',
      from: range?.from ? formatEngine(range.from) : '',
    };
  }

  function enginePowerFormatter(range: IGetAdsRequestRange) {
    return addRangeDescriptor(range, ' HP');
  }

  function batteryRangeFormatter(range: IGetAdsRequestRange) {
    return addRangeDescriptor(range, '+ km');
  }

  function seatsFormatter(range: IGetAdsRequestRange) {
    return addRangeDescriptor(range, ' Seats');
  }

  function mileageFormatter(range: IGetAdsRequestRange) {
    return addRangeDescriptor(range, ' km');
  }

  function rangeFormatter(range: IGetAdsRequestRange): IGetAdsRequestRange {
    switch (range.name) {
      case 'price':
        return priceFormatter(range);
      case 'engine':
        return engineFormatter(range);
      case 'enginePower':
        return enginePowerFormatter(range);
      case 'batteryRange':
        return batteryRangeFormatter(range);
      case 'seats':
        return seatsFormatter(range);
      case 'mileage':
        return mileageFormatter(range);
      default:
        return range;
    }
  }

  const generateSaveSearchTerms = (maxLength: number = 5): Array<string> => {
    const terms: string[] = [sectionName];

    const makeModelFilters = makeModel.filter((item) => item.makeValue !== '');

    // Make Models
    if (makeModelFilters && makeModelFilters.length > 0) {
      for (let makeModelItem of makeModel) {
        const { makeValue, modelValue } = makeModelItem;
        const hasMakeValue = makeValue && makeValue !== 'All Makes';
        if (hasMakeValue && modelValue.length === 0) {
          terms.push(`${makeValue} (All Models)`);
        } else if (hasMakeValue && modelValue.length === 1) {
          terms.push(`${makeValue} ${modelValue[0]}`);
        } else if (hasMakeValue && modelValue.length > 1) {
          terms.push(
            `${makeValue}, ${modelValue[0]} (+${modelValue.length - 1})`,
          );
        }
      }
    }

    if (keywordSearchTerm) {
      terms.push(`'${keywordSearchTerm}'`);
    }

    if (county) {
      if (countyTown && radius) {
        terms.push(`${countyTown}, ${county} (+${radius}km)`);
      } else terms.push(`${county} (All Areas)`);
    }

    // Filters
    for (const item of filters) {
      const filter = filterFormatter(item);
      const filterData = getFilterData(item.name);
      if (filterData?.values) {
        for (const value of filter.values) {
          if (!value) break;
          const filterDataValue = filterData?.values.find(
            (item) => item.value === value,
          );
          terms.push(filterDataValue?.displayName || value);
        }
      }
    }

    // Ranges
    for (const item of ranges) {
      const range = rangeFormatter(item);

      if (range.from && range.to && range.from === range.to) {
        terms.push(`${range.from}`);
      } else {
        if (range.from && range.to) {
          terms.push(`${range.from} - ${range.to}`);
        } else if (range.from) {
          terms.push(`>${range.from}`);
        } else if (range.to) {
          terms.push(`<${range.to}`);
        }
      }
    }

    if (sort) {
      const sortDisplayName = sortFields.find((item) => item.name === sort);
      terms.push(`${sortDisplayName?.displayName}`);
    }

    return truncateSearchTerms(terms, maxLength);
  };

  function generateSavedSearchName() {
    let saveSearchName = '';

    const makeModelFilters = makeModel.filter(
      (item: MakeModelItem) => item.makeValue !== '',
    );

    if (makeModelFilters.length > 0) {
      if (makeModelFilters.length === 1) {
        const { makeValue, modelValue } = makeModelFilters[0];
        if (makeValue && makeValue !== 'All Makes' && modelValue.length === 1) {
          saveSearchName = `${makeValue} ${modelValue[0]}`;
        } else if (
          makeValue &&
          makeValue !== 'All Makes' &&
          (modelValue.length > 1 || modelValue.length === 0)
        ) {
          saveSearchName = `${makeValue}`;
        } else {
          saveSearchName = '';
        }
      } else {
        saveSearchName = `${makeModelFilters[0].makeValue} (+${
          makeModelFilters.length - 1
        }) `;
      }
    }

    return saveSearchName
      ? `${saveSearchName} in ${sectionName}`
      : `${keywordSearchTerm ? `${keywordSearchTerm} in ` : ''}${sectionName}`;
  }

  function checkIsSaveSearchDisabled() {
    if (saved) {
      return true;
    }
    return getCountOfFiltersApplied(keywordSearchTerm, sort) === 0;
  }

  async function handleOnClickSave() {
    if (user?.id) {
      try {
        const { data } = await savedSearchApi.searchPagePreCheck(
          user.id.toString(),
          {
            decayFunctions: [],
            makeModelFilters: mapMakeModelFilters(makeModel),
            filters: mapFiltersRequest(filters),
            ranges: mapRangesRequest(ranges),
            sort,
            terms: keywordSearchTerm,
            sections: [section],
            scoringRanges: [],
            scoringFilters: [],
            notFilters: [],
            paging: { pageSize: 30, from: 0 },
          },
          formatAuthorizationHeader(accessToken),
          API_CLIENT_TIMEOUT,
        );
        if (data.instantAlert) {
          setIsInstantAlertAvailable(true);
        } else {
          setIsInstantAlertAvailable(false);
          setSelected(NotificationFrequency.DAILY);
        }
        setIsSavedSearchModalOpen(true);
      } catch (error) {
        rg4js('send', {
          error: new Error('Error handling handleOnClickSave'),
          tags: [PAGE.SEARCH],
          customData: {
            message: error.message || 'client_error',
          },
        });
        if (
          error?.response?.status === StatusCode.ClientErrorUnprocessableEntity
        ) {
          fireToast({
            type: 'ERROR',
            text: (
              <>
                You already have a Saved Search for these filters.{' '}
                <Link
                  ofType="SECONDARY"
                  inline
                  href={`${baseUrl}/dashboard/searches`}
                  underline
                >
                  View all Saved Searches
                </Link>
              </>
            ),
            closeOnClick: false,
          });
        } else {
          fireToast({
            type: 'ERROR',
            text: 'Oops! Something went wrong, please try again later',
          });
        }
      }
    } else {
      handleLogin();
    }
  }

  useOnUpdateOnly(() => {
    if (saved) {
      setIsSavedSearchModalOpen(false);
      fireToast({
        type: 'SUCCESS',
        text: (
          <>
            Search Saved! Remember you can manage your preferences in{' '}
            <Link
              href={`${baseUrl}/dashboard/${USER_MENU.SEARCHES}`}
              ofType="SECONDARY"
              inline
              underline
            >
              My DoneDeal
            </Link>
          </>
        ),
      });
    }
  }, [saved]);

  useOnUpdateOnly(() => {
    if (error) {
      fireToast({
        type: 'ERROR',
        text: error,
      });
    }
  }, [error]);

  useOnUpdateOnly(() => {
    if (saved) {
      setSaved(false);
    }
  }, [
    filters,
    ranges,
    sort,
    keywordSearchTerm,
    makeModel,
    county,
    countyTown,
    radius,
  ]);

  const savedSearchTerms = generateSaveSearchTerms();

  return {
    handleOnClickSave,
    savedSearchTerms,
    isSavedSearchDisabled: checkIsSaveSearchDisabled(),
    savedSearchName: name,
    setSavedSearchName: setName,
    createSavedSearch,
    saved,
    saving,
    selected,
    setSelected,
  };
}

export { useSavedSearch };
